import { keyframes } from "@emotion/react"

const slideInLeftCenter = keyframes`
  0% {
    transform: translateX(0%);
  }
  33% {
    transform: translateX(100%);
    visibility:hidden;
  }
  66% {
    transform: translateX(-100%);
    visibility:hidden;
  }
  100% {
    transform: translateX(0%);
    visibility:visibile;
  }
`

const theme = {
  colors: {
    text: "#222222",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#E63312",
    secondary: "#FFAC9C",
    light: "#ffffff",
    dark: "#222222",
    grey: "#E5E2DD",
    borderLightGrey: "#ECEDF0",
    lightGrey: "#999999",
    compassi: "#A81815",
  },
  fonts: {
    compassi:
      '"ITC Officina Serif Std", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    body:
      '"NeueHaasGroteskDisp Pro", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"NeueHaasGroteskDisp Pro", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    footer:
      '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 10, 15, 21, 30, 45, 60, 96, 106, 128, 162, 192, 256, 512],
  fontSizes: [12, 15, 18, 22, 24, 28, 36, 45, 50, 60, 90, 120, 140],
  fontWeights: {
    body: 400,
    heading: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.056,
  },
  sizes: {
    container: 1150,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [7, 9],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "dark",
      maxWidth: "1110px",
      my: [3, 5],
    },
    h2: {
      fontSize: [7, 9],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    kicker: {
      letterSpacing: "5px",
      lineHeight: "1.75",
      fontSize: [1],
      fontWeight: "600",
      textTransform: "uppercase",
      color: "primary",
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  forms: {
    input: {
      fontSize: [3, 3, 4, 4],

      borderRadius: 0,
      color: "light",
      border: "none",
      borderBottom: "1px solid",
      borderColor: "light",
      "&::placeholder": {
        color: "light",
        opacity: 0.6,
      },
    },
    textarea: {
      fontSize: [3, 3, 4, 4],

      borderRadius: 0,
      color: "light",
      border: "none",
      borderBottom: "1px solid",
      borderColor: "light",
      "&::placeholder": {
        color: "light",
        opacity: 0.6,
      },
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  arrowAnimation: {
    "&:hover": {
      "> div ": {
        overflow: "hidden",
        svg: {
          animation: `${slideInLeftCenter} 0.4s linear .25s forwards`,
        },
      },
    },
  },
  styles: {
    root: {
      "*::selection": {
        background: "primary",
      },
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        "&:hover": {
          color:"dark",
          
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#00a889",
    },
  },
}

export default theme
